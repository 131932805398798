.countryDetails-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.country-container-details-btn {
  text-align: center;
  margin-bottom: 1rem;
}

.map-btn-details {
  margin: 0.5rem;
}
.countryDetails-link,
.countryDetails-link:link,
.countryDetails-link:visited {
  text-decoration: none;
  background-color: var(--Blue);
  color: white;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--Blue);
  margin: 1rem;
}

.countryDetails-link:hover {
  opacity: 0.8;
}
.countryDetails-table-grid {
  display: grid;
  grid-template-rows: 2rem 2rem 1fr;
  width: 70vw;
}

.countryDetails-table {
  margin: 0 auto;
  border-collapse: collapse;
}

.countryDetails-table-row {
  display: grid;
  grid-template-columns: 0.5fr 0.9fr 1fr;
  background-color: var(--Blue);
  color: white;
  font-size: 12px;
}
.countryDetails-icon {
  position: relative;
}

.arrow-top {
  position: absolute;
  top: -42%;
  left: 0.7rem;
}
.arrow-down {
  position: absolute;
  bottom: -42%;
  left: 0.7rem;
}

.countryDetails-table-th {
  margin-top: -12px;
  font-size: 1rem;
  background-color: var(--Blue);
}

.countryDetail th,
.countryDetail td {
  padding: 16px 24px;
  text-align: left;
}

.countryDetail tbody tr:nth-child(odd) {
  background-color: var(--lightGrey);
}

.countryDetail tbody tr:nth-child(even) {
  background-color: var(--darkGrey);
}

.countryDetails-source {
  border: 1px solid blue;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  color: blue;
  text-decoration: none;
}

.countryDetails-source:hover {
  text-decoration: underline;
}

.countryDetails-source:visited {
  color: purple;
  border: 1px solid purple;
}
