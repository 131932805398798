/*Legend */
.legend {
  display: flex;
  gap: 3px;
  position: absolute;
  left: 40%;
  bottom: 1rem;
  background-color: var(--Blue);
  padding: 7px;
  border: 1px solid gainsboro;
  color: white;
  font-size: 12px;
}

.legend-square {
  height: "1rem";
  width: "1rem";
  display: "inline-block";
}

.hide {
  display: none;
}

.legend-map:hover .hide {
  display: block;
}

.legend-map {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
