.country-container {
  display: flex;
  flex-direction: column;
  background-color: var(--Blue);
  overflow-y: auto;
  overflow-x: hidden;
}

.country-container-btn {
  text-align: center;
}

.country-container-btn > :last-child {
  float: right;
  margin-top: 0.8rem;
}

.country-container-map {
  display: grid;
  grid-template-columns: 22% 1fr;
  position: relative;
  height: calc(100vh - 73px);
  gap: 0.5rem;
  padding-left: 0.5rem;
}

.country-container-map-alone {
  display: grid;
  grid-template-columns: 1fr;
}

.country-print-btn {
  position: absolute;
  bottom: 16px;
  padding: 0.4rem 0.6rem;
  left: 8%;
  border: 1px solid white;
  background-color: var(--Blue);
  color: white;
}
.map-btn {
  margin: 12px;
  background-color: white;
  width: auto;
  padding: 0.7rem;
  color: var(--Blue);
}
.active {
  background-color: var(--darkerGray);
  color: white;
  font-weight: bold;
}

.btn-select-year {
  padding: 0.5rem 0 0.5rem 0;
  border-radius: 5px;
  margin-right: 5px;
}

.map-container {
  height: 100%;
  width: 100%;
  grid-column: 2/3;
  grid-row: 1/3;
  position: relative;
}
.map-view {
  position: relative;

  background-color: var(--Blue);
  height: 100%;
  width: 100%;
}

.country-chart {
  padding: 1rem;
}

.country-legend {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  background-color: var(--Blue);
  padding: 10px;
  border: 1px solid gainsboro;
  color: white;
}

/*circles for the documents*/
.circleList {
  display: flex;
  gap: 0.16rem;
  width: max-content;
  align-items: center;
  position: relative;
  padding: 0.5rem 0;
}

.rect {
  position: absolute;
  width: 0.2rem;
  height: 70%;
  background-color: var(--Blue);
}

.rect-first {
  left: 0.25rem;
  transform: translateX(-50%);
}

.line {
  position: absolute;
  right: 0.91rem;
  left: 2.9rem;
  height: 0.1rem;
  background-color: var(--Blue);
}

.rect-last {
  right: 0.91rem;
  transform: translateX(50%);
}

.rect-middle {
  width: 0.1rem;
  left: 2.91rem;
  transform: translateX(-50%);
}

.circle {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: var(--darkerGray);
}

.circle-p {
  margin-right: -0.2rem;
}
.circleList-p {
  display: flex;
  width: max-content;
  align-items: center;
  position: relative;
  padding: 0.5rem 0;
}
/* circle for the Perceived documents*/
.rect-p {
  position: absolute;
  width: 0.2rem;
  height: 85%;
  background-color: var(--Blue);
}

.rect-first-p {
  left: 0.2rem;
  transform: translateX(-50%);
}

.rect-last-p {
  left: 5.75rem;
  transform: translateX(50%);
}

.rect-middle-p {
  width: 0.1rem;
  left: 2.75rem;
  transform: translateX(-50%);
}

.rect-first-p {
  width: 0.2rem;
  left: 0.4rem;
  transform: translateX(-50%);
}

.rect-first-p-left {
  width: 0.2rem;
  left: 3.7rem;
  transform: translateX(-50%);
  background-color: var(--Blue);
}

.line-p-first {
  position: absolute;
  right: 6rem;
  left: 0.3rem;
  height: 0.1rem;
  background-color: var(--Blue);
}

.line-p-second {
  position: absolute;
  right: 3.02rem;
  left: 3.6rem;
  height: 0.1rem;
  background-color: var(--Blue);
}

.circle-outline {
  margin-left: 2rem;
  display: flex;
  gap: 4rem;
}

.circle-outline-gap {
  display: flex;
  gap: 1rem;
}
.logo {
  width: 12%;
  height: 12%;
}

.esri-attribution {
  display: none;
}

.esri-popup__header {
  background-color: var(--Blue);
}

.esri-view-width-xlarge .esri-popup__main-container {
  width: 250px;
}

.esri-view-height-less-than-medium .esri-popup__main-container {
  max-height: 330px;
}

.esri-feature-content p {
  font-size: 11px;
  margin: 0.2rem;
}
.esri-feature-content p span {
  font-size: 11px;
  margin: 0.2rem;
}

.esri-print__advanced-options-button {
  display: none;
}

.popup-hover {
  position: absolute;
  pointer-events: none;
  background-color: #242424;
  padding: 0.1rem 1rem;
  font-weight: 500;
  color: white;
  font-size: 0.8rem;
}

.selected-title {
  font-weight: 800;
  border-bottom: 1px solid white;
  text-align: center;
  margin-bottom: 5px;
}

@media (max-width: 1076px) {
  .country-container-map {
    grid-template-columns: 1fr;
    gap: 1rem;
    display: initial;
  }
  .country-container-btn > .btn:last-child {
    font-size: 10px;
    margin-right: 12px;
  }

  .map-view {
    width: 100%;
  }

  .country-print-btn {
    bottom: 3%;
    left: 5%;
    width: auto;
  }

  .map-container {
    grid-column: 1/2;
    grid-row: unset;
  }
}

@media (max-width: 440px) {
  .country-container-btn {
    text-align: left;
  }
  .country-container-btn > .btn:last-child {
    font-size: 10px;
    margin-right: 12px;
  }
  .map-btn {
    width: max-content;
    font-size: 9px;
  }
  .country-print-btn {
    bottom: 1%;
    left: 1%;
  }
}
