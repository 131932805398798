.dropdown-container {
  display: flex;
  justify-content: right;
  background-color: white;
  padding: 0.5rem 0.5rem;
  max-width: 100%;
}

.dropdown-label {
  font-size: 0.7rem;
  color: var(--darkerGray);
  overflow: hidden;
}
.drawer-paper {
  width : 300px;
}

.dropdown-select {
  width: 90%;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid var(--Blue);
}

.dropdown-header {
  padding: 5px;
}

.dropdown-header-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.dropdown-arrow {
  padding: 4px;
  background-color: white;
  border: 1px solid var(--Blue);
  color: var(--Blue);
  margin: 6px 10px;
}
.dropdown-arrow:hover {
  color: var(--lightBlue);
  border: 1px solid var(--lightBlue);
}

.dropdown-box {
  border: 2px solid var(--lightBlue);
  margin: 0.5rem;
  font-size: 0.8rem;
  width: 90%;
}

.dropdown-box-header {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  align-items: start;
  padding: 0.2rem 0.5rem;
  gap: 0.5rem;
  background-color: var(--Blue);
  color: white;
  position: relative;
  padding-right: 1.4rem;
  font-weight: bold;
  width: 100%;
}

.dropdown-icon {
  background-color: var(--Blue);
  border: none;
  position: absolute;
  right: 0.5rem;
  top: 0.3rem;
  color: white;
}
.dropdown-box-description {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  text-align: center;
  color: var(--Blue);
  padding: .5rem 0.5rem;
}

.dropdown-box-description-last {
  display: grid;
  grid-template-columns: auto auto; 
  gap: 10px
}

.dropdown-link,
.dropdown-link-text {
  padding: 0.5rem;
  font-stretch: condensed;
  color: var(--Blue);
  text-decoration: none;
}


.dropdown-link:hover {
  color: var(--lightBlue);
}
.dropdown-box-image {
  margin: 5px;
  width: 20px;
  height: 20px;
}

