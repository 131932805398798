.lolli-container {
  overflow: auto;
  /*max-height: 600px;*/
  height: 100%;
  padding: 0 1.6rem;
  grid-column: 1/2;
  width: auto;
  color: white;
  box-shadow: 0 2px 10px var(--Yellow);
  background-color: var(--Black);
}
.lolli-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  font-size: 0.8rem;
  border-bottom: 2px solid #021d34;
  padding: 0.3rem;
}

.lolli-table-header {
  background-color: var(--Blue);
  color: white;
  padding: 0.3rem;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--Blue);
}

.lolli-table-header-text {
  font-size: 10px;
}

.lolli-table-header:hover {
  opacity: 0.8;
}
.lolli-table-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  font-size: 0.8rem;
  padding: 0 0.3rem;
  margin-top: 0.1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease;
}

.lolli-table-row:hover {
  background-color: lightgray;
  color: var(--Blue);
  font-weight: bold;
}

.lolli-table-row-four {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
}

.lolli-table-description {
  position: relative;
  text-align: center;
}

.lolli-table-last-row {
  min-height: 26px;
  height: max-content;
  position: relative;
}
.lolli-score-bg {
  background-color: #e6e6e6;
  width: calc(100% + 1.3rem);
  display: block;
  min-height: 26px;
  height: 100%;
}

.lolli-score-bar {
  height: 0.4rem;
  background-color: #021d34;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.lolli-score-circle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background-color: var(--lightBlue);
}

.lolli-score-number {
  font-size: smaller;
}
/*styling scroll*/
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #021d34;
  border-radius: 10px;
}
