@import url("https://fonts.cdnfonts.com/css/neue-haas-grotesk-text-pro");

:root {
  --Blue: #1a3761;
  --Yellow: #dbad50;
  --lightBlue: #82a0bc;
  --Red: #ae1917;
  --meduimRed: #c48469;
  --green: #4e8341;
  --darkGray: #a9abb8;
  --darkerGray: #a4a58d;
  --lightGray: #f0f0ef;
  --Black: #242424;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
* {
  font-family: "neue haas grotesk text pro", arial !important;
  line-height: 1.7;
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-weight: 700;
  color: #072b44;
  letter-spacing: -0.5px;
}

.heading-primary {
  font-size: 3rem;
  line-height: 1.05;
  margin-bottom: 1rem;
}

.heading-secondary {
  font-size: 2.2rem;
  line-height: 1;
  margin-bottom: 0.75rem;
}

.heading-tertiary {
  font-size: 1.5rem;
  line-height: 0.75;
  margin-bottom: 0.5rem;
}

.btn {
  padding: 0.4rem 0.4rem;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.btn:hover {
  opacity: 0.8;
}
