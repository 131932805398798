.country-selected-container {
  margin: auto 5%;
  background-color: var(--lightGray);
}

.country-selected-header-container {
  text-align: center;
}

.country-selected-header {
  position: relative;
  display: inline-block;
  padding: 1rem;
}

.country-selected-header:before,
.country-selected-header:after {
  content: "";
  display: block;
  position: absolute;
  width: 80%;
  height: 2px;
  top: 50%;
  background: var(--Blue);
}

.country-selected-header:before {
  transform: translateY(50%) translateX(-110%);
}

.country-selected-header:after {
  transform: translateY(-50%) translateX(120%);
}

.country-selected-link,
.country-selected-link:link,
.country-selected-link:visited {
  text-decoration: none;
  background-color: var(--Blue);
  color: white;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--Blue);
  margin: 1rem;
  float: right;
}

.country-selected-link:hover {
  opacity: 0.8;
}

.country-selected-container-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
}

.country-selected-box {
  background-color: #ccc;
  flex-basis: 45%;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(70, 74, 137, 0.7);
}

.country-selected-box-header {
  font-size: 20px;
  text-transform: uppercase;
  background-color: var(--Blue);
  color: white;
  line-height: 1.5;
  border-left: 8px solid var(--Yellow);
  padding: 0.5rem;
}

.country-selected-box-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 0.5rem;
}

.country-selected-box-content-text {
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: var(--Blue);
  border-bottom: 2px solid var(--Blue);
}

.country-selected-box-content-number {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  font-size: 20px;
  color: var(--Blue);
}

.country-selected-box-content-span {
  font-size: 15px;
  color: var(--Blue);
}

.country-selected-box-content-p {
  font-size: 20px;
  color: var(--Blue);
  text-align: center;
}

.country-selected-description{
  margin-top: 1rem;
}
@media (max-width: 1076px) {
  .country-selected-container-box {
    flex-direction: column;
  }

  .country-selected-header:before,
  .country-selected-header::after {
    display: none;
  }
  .country-selected-box {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .country-selected-box-content {
    display: flex;
    flex-direction: column;
  }
}
