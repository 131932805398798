.avg-table {
  width: 100%;
  background-color: var(--Black);
  color: white;
  padding-left: 1rem;
}
.avg-description {
  background-color: var(--Black);
  color: white;
  font-size: 14px;
  text-align: center;
}
.avg-table th,
.avg-table td {
  padding: 0.2rem;
}

.avg-header,
.avg-cell {
  text-align: left;
}

.avg-rows {
  text-align: center;
  font-size: small;
}

@media (max-width: 1076px) {
  .avg-header,
  .avg-cell {
    text-align: center;
  }
}
