.pastData-container {
  font-family: "neue-haas-grotesk-text-pro", arial;
  overflow-x: hidden;
  overflow-y: hidden;
}

.pastData-header {
  display: flex;
  align-items: center;
  gap: 3rem;
  justify-content: center;
}

.pastData-link {
  border: 1px solid var(--Blue);
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  background-color: var(--Blue);
  justify-items: end;
}

.pastData-name {
  text-align: center;
  font-size: 3rem;
  color: var(--Blue);
  background-image: linear-gradient(to right, var(--Blue), var(--lightBlue));
  -webkit-background-clip: text;
  color: transparent;
}

.pastData-date {
  font-size: 2.5rem;
  color: var(--darkerGray);
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--darkGray);
}

.pastData-description {
  background-color: var(--Blue);
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
}

.pastData-text {
  padding: 1rem;
  text-align: left;
}
